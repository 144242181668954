export default {
  name: {
    name: 'name',
    sortable: true,
    label: 'Должность',
    style: 'width:180px;text-align:left',
  },
  rate: {
    name: 'rate',
    sortable: true,
    label: 'Рейт',
    style: 'width:100px;text-align:left',
  },
  plan: {
    name: 'plan',
    sortable: true,
    label: 'План',
    style: 'width:100px;text-align:left',
  },
  left: {
    name: 'left',
    sortable: true,
    label: 'Остаток',
    style: 'width:100px;text-align:left',
  },
}
