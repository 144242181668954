<template>
  <div class="table">
    <div v-if="!hideHeader" class="table__header mb-1">
      <slot name="panel"></slot>
      <div class="table__thead bg-white">
        <div class="table__tr">
          <div class="table__th pr-8">
            <div class="table__th-content">
              <div style="width: 40px"></div>
              <div v-for="col in cols" :key="col.name" :style="col.style">
                <div
                  :class="thLabelClasses(col)"
                  class="table__th-label"
                  @click="setSortDirection(col.name)"
                >
                  {{ col.label }}
                </div>
              </div>

              <div class="table__th-week ml-auto">
                <div v-for="date in datesRange" style="width: 70px">
                  <div class="table__th-label text-right text-grey-light">
                    {{ date.format('dd') }}
                  </div>
                  <div class="table__th-value text-right">
                    {{ date.format('DD.MM') }}
                  </div>
                </div>
                <div class="active" style="width: 110px">
                  <div class="table__th-label text-right text-grey-light">
                    Тек неделя
                  </div>
                  <div class="table__th-value text-right">
                    {{ weekLabel(datesRange[0]) }}
                  </div>
                </div>
                <div @click="incrementWeek(-1)">
                  <BmIconChevronLeft class="table__th-prev" />
                </div>
                <div @click="incrementWeek(1)">
                  <BmIconChevronRight class="table__th-next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <skeleton-table v-if="loading" :rows-count="positions.length || 0" />
    <template v-else>
      <OverlayScrollbars>
        <div class="table__tbody">
          <div
            v-for="(position, positionIndex) in positions"
            :key="position.id"
            class="table__tr table__tr_body"
            @click="expandPosition(position.id)"
          >
            <div :class="{table__td_active: position.expand}" class="table__td">
              <div
                :class="{'table__td-content_active': position.expand}"
                class="table__td-content"
              >
                <div style="width: 40px">
                  <BmIconChevronRight
                    :class="{'table__expand-icon_expanded': position.expand}"
                    class="table__expand-icon"
                    color="black"
                  />
                </div>
                <div :style="cols.name.style">
                  <div class="table__td-label">{{ position.name }}</div>
                </div>
                <div :style="cols.rate.style">
                  <div class="">{{ position.rate }}</div>
                </div>
                <div :style="cols.plan.style">
                  <div class="stage-plan">
                    <TableTextInput
                      v-if="planUnit === 'hour'"
                      :max="999999999"
                      :value="inUnit(position.scheduledTime, position.rate)"
                      @input="updateStagePlan(position, $event, positionIndex)"
                      @click.native.stop
                    />
                    <div v-else>
                      {{ inUnit(position.scheduledTime, position.rate) }}
                    </div>
                  </div>
                </div>
                <div :style="cols.left.style">
                  <div class="">
                    {{ inUnit(position.remainingTime, position.rate) }}
                  </div>
                </div>
                <div class="flex items-center ml-auto">
                  <div
                    v-for="date in datesRange"
                    :key="'day' + date.format('YYYY-MM-DD')"
                    :class="{
                      'text-grey-light': date.format('YYYY-MM-DD') === 0,
                      'text-red': date.format('YYYY-MM-DD') > 36,
                    }"
                    class="text-right pl-4"
                    style="width: 70px"
                  >
                    {{ getPositionDateDuration(position, date) / 3600 }} ч
                  </div>
                  <div class="text-right" style="width: 110px">
                    {{ getPositionDuration(position) / 3600 }} ч
                  </div>
                </div>
                <div class="text-right" style="width: 30px">
                  <BmButton
                    bg-color="white"
                    class="table__icon-more"
                    dropdown-left
                    size="xs"
                    @click.native.stop
                  >
                    <BmIconMore />
                    <template #dropdown>
                      <div class="drop-menu">
                        <div
                          class="drop-menu__item"
                          @click="detachPosition(position)"
                        >
                          <div class="flex items-center text-red">
                            <BmIconTrash class="mr-2" />
                            Удалить
                          </div>
                        </div>
                      </div>
                    </template>
                  </BmButton>
                </div>
              </div>
              <div
                :class="{table__expansion_active: position.expand}"
                class="table__expansion"
              >
                <div
                  v-for="user in users"
                  v-if="user.positionId === position.id"
                  :key="user.id"
                  class="table__td-content no-border"
                  @click.stop
                >
                  <div style="width: 40px"></div>
                  <div class="flex items-center">
                    <BmAvatar
                      v-tooltip="user.name"
                      :src="user.avatar"
                      class="mr-4"
                    >
                    </BmAvatar>

                    <div class="fw-400">
                      <div>{{ user.name }}</div>
                      <div class="text-grey-dark">{{ user.unitsDisplay }}</div>
                    </div>
                  </div>

                  <div class="flex items-center ml-auto">
                    <div
                      v-for="day in datesRange"
                      :key="user.id + 'day' + day.format('YYYY-MM-DD')"
                      :class="{
                        'text-grey-light':
                          getUserDateScheduledTime(user, day).duration === 0,
                        'text-red':
                          getUserDateScheduledTime(user, day).duration > 28800,
                      }"
                      class="text-right pl-4"
                      style="width: 70px"
                    >
                      <TableTextInput
                        :is-syncing="scheduledTimeSyncing(user, day)"
                        :max="8"
                        :value="
                          getUserDateScheduledTime(user, day).duration / 3600
                        "
                        @input="
                          updateScheduledTime(user, day, $event, positionIndex)
                        "
                        @click.native.stop
                      />
                    </div>
                    <div class="text-right" style="width: 110px">
                      {{ getUserDuration(user) / 3600 }} ч
                    </div>
                  </div>
                  <div class="text-right" style="width: 30px">
                    <BmButton
                      bg-color="white"
                      class="table__icon-more"
                      dropdown-left
                      size="xs"
                    >
                      <BmIconMore />
                      <template #dropdown>
                        <div class="drop-menu">
                          <div
                            class="drop-menu__item"
                            @click="detachUser(user, position)"
                          >
                            <div class="flex items-center text-red">
                              <BmIconTrash class="mr-2" />
                              Удалить
                            </div>
                          </div>
                        </div>
                      </template>
                    </BmButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbars>
    </template>
  </div>
</template>

<script>
import api from '@/api'
import {mapActions, mapState, mapMutations} from 'vuex'
import {numberWithSpaces, weekLabel, daysRange} from '@src/utils/helpers'
import OverlayScrollbars from '@src/components/overlay-scrollbars/OverlayScrollbars'

import SkeletonTable from './SkeletonStageTable'
import TableTextInput from './TableTextInput'
import cols from '../_config/projectPageCols'

export default {
  name: 'StageTable',

  components: {
    OverlayScrollbars,
    TableTextInput,
    SkeletonTable,
  },

  props: {
    stage: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

    planUnit: {
      type: String,
      default: 'hour',
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cols: cols,
      currentRows: [],
      positions: [],
      users: [],
      scheduledTimes: [],
      dbScheduledTimes: [],
      changedScheduledTimes: [],
      changedPlans: [],
      currentSortColumnName: '',
      changedScheduledTimesTimer: null,
      changedPlansTimer: null,
      datesRange: [],
      sortDirection: {
        name: null,
        rate: null,
        plan: null,
        left: null,
      },
    }
  },

  computed: {
    ...mapState({
      filterDays: (state) => state.projects.filterDays,
    }),
  },

  methods: {
    ...mapMutations({
      setFilterDays: 'projects/setFilterDays',
    }),
    ...mapActions({
      syncScheduledTimes: 'stages/syncScheduledTimes',
    }),
    weekLabel,
    daysRange,
    async incrementWeek(increment) {
      const date = this.$moment(this.filterDays.id, 'YYYY-MM-DD').add(
        increment,
        'w'
      )

      this.setFilterDays({
        id: date.format('YYYY-MM-DD'),
        name: this.weekLabel(date),
      })
    },
    getUserDateScheduledTime(user, date) {
      return this.scheduledTimes.filter(
        (scheduledTime) =>
          scheduledTime.userId === user.id &&
          scheduledTime.scheduledDate === date.format('YYYY-MM-DD')
      )[0]
    },
    getPositionDateScheduledTime(position, date) {
      const userIds = this.getUsersByPosition(position).map((user) => user.id)

      return this.scheduledTimes.filter(
        (scheduledTime) =>
          userIds.includes(scheduledTime.userId) &&
          scheduledTime.scheduledDate === date.format('YYYY-MM-DD')
      )
    },
    getPositionScheduledTime(position) {
      const userIds = this.getUsersByPosition(position).map((user) => user.id)

      return this.scheduledTimes.filter((scheduledTime) =>
        userIds.includes(scheduledTime.userId)
      )
    },
    getPositionDateDuration(position, date) {
      return this.lodash.sumBy(
        this.getPositionDateScheduledTime(position, date),
        'duration'
      )
    },
    getPositionDuration(position, date) {
      return this.lodash.sumBy(
        this.getPositionScheduledTime(position, date),
        'duration'
      )
    },
    getUsersByPosition(position) {
      return this.users.filter((user) => user.positionId === position.id)
    },
    getUserScheduledTime(user) {
      return this.scheduledTimes.filter(
        (scheduledTime) => scheduledTime.userId === user.id
      )
    },
    getUserDuration(user) {
      return this.lodash.sumBy(this.getUserScheduledTime(user), 'duration')
    },
    scheduledTimeSyncing(user, day) {
      return (
        this.changedScheduledTimes.filter(
          (scheduledTime) =>
            scheduledTime.userId === user.id &&
            scheduledTime.scheduledDate === day.format('YYYY-MM-DD')
        ).length > 0
      )
    },
    updateScheduledTime(user, day, hours, positionIndex) {
      let scheduledTime = this.getUserDateScheduledTime(user, day)
      const duration = hours * 3600
      if (scheduledTime.duration !== duration) {
        this.positions[positionIndex].remainingTime +=
          scheduledTime.duration - duration
        scheduledTime.duration = duration
        this.changedScheduledTimes.push(scheduledTime)
      }
      this.syncChangedScheduledTimes()
    },
    updateStagePlan(position, plan, positionIndex) {
      const value = parseInt(plan.toString().match(/\d+/g)?.join('')) * 3600

      if (this.positions[positionIndex].scheduledTime !== value) {
        this.positions[positionIndex].remainingTime -=
          this.positions[positionIndex].scheduledTime - value
        this.changedPlans[positionIndex] = this.positions[positionIndex]
        this.changedPlans[positionIndex].scheduledTime = value
      }

      this.changedPlans = this.changedPlans.filter(
        (changedPlan) => changedPlan.id
      )

      this.syncChangedStagePlans()
    },
    syncChangedScheduledTimes() {
      clearTimeout(this.changedScheduledTimesTimer)

      if (this.changedScheduledTimes.length) {
        this.changedScheduledTimesTimer = setTimeout(
          async function () {
            const stage = await this.syncScheduledTimes({
              stageId: this.stage.id,
              scheduledTimes: this.changedScheduledTimes,
            })
            this.dbScheduledTimes = stage.scheduledTimes
            this.changedScheduledTimes = []
          }.bind(this),
          1000
        )
      }
    },
    syncChangedStagePlans() {
      clearTimeout(this.changedPlansTimer)

      if (this.changedPlans.length) {
        this.changedPlansTimer = setTimeout(
          async function () {
            await api.stages.updatePositions(this.stage.id, this.changedPlans)
            this.changedPlans = []
          }.bind(this),
          1000
        )
      }
    },
    inUnit(value, rate) {
      const hours = value / 3600
      return this.planUnit === 'rub'
        ? this.numberWithSpaces(hours * rate) + ' ' + '₽'
        : hours + ' ' + 'ч'
    },
    expandPosition(positionId) {
      this.$emit('expand:position', positionId)
    },
    setSortDirection(name) {
      this.currentSortColumnName = name
      this.sortDirection[name] = !this.sortDirection[name]
        ? 'asc'
        : this.sortDirection[name] === 'asc'
        ? 'desc'
        : null
      Object.keys(this.sortDirection).forEach((key) => {
        if (key !== name) {
          this.sortDirection[key] = null
        }
      })
    },
    thLabelClasses(column) {
      return [
        column.sortable ? 'table__th-sortable' : '',
        column.sortLeft ? 'table__th-sortable_left' : '',
        this.sortDirection[column.name]
          ? 'table__th-sortable_' + this.sortDirection[column.name]
          : '',
      ]
    },
    detachUser(user, position) {
      this.$emit('detach:user', {
        stageId: this.stage.id,
        userId: user.id,
        positionId: position.id,
      })
    },
    detachPosition(position) {
      this.$emit('detach:position', {
        stageId: this.stage.id,
        positionId: position.id,
      })
    },
    numberWithSpaces,
    render() {
      this.scheduledTimes = []
      this.datesRange.forEach(
        function (day) {
          this.users.forEach(
            function (user) {
              const dbScheduledTime = this.dbScheduledTimes.filter(
                (dbScheduledTime) =>
                  dbScheduledTime.userId === user.id &&
                  dbScheduledTime.scheduledDate === day.format('YYYY-MM-DD')
              )
              this.scheduledTimes.push({
                userId: user.id,
                scheduledDate: day.format('YYYY-MM-DD'),
                duration: dbScheduledTime.length
                  ? dbScheduledTime[0].duration
                  : 0,
                changed: false,
              })
            }.bind(this)
          )
        }.bind(this)
      )
    },
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        this.positions = this.stage.positions
        this.users = this.stage.users
        this.dbScheduledTimes = this.stage.scheduledTimes
        this.render()
      },
    },
    filterDays() {
      this.datesRange = this.daysRange(this.filterDays.id)
      this.render()
    },
    sortDirection() {
      if (this.sortDirection === 'asc') {
        this.currentRows.sort((a, b) => a.name.localeCompare(b.name))
      } else if (this.sortDirection === 'desc') {
        this.currentRows.sort(() => -1)
      } else {
        this.currentRows = [...this.rows]
      }
    },
  },
  created() {
    const date =
      this.filterDays?.id ?? this.$moment().weekday(0).format('YYYY-MM-DD')

    this.setFilterDays({
      id: date,
      name: this.weekLabel(date),
    })
  },
}
</script>
