<template>
  <div style="overflow: hidden; max-height: 670px">
    <CSearchInput
      :chips.sync="selectedPositions"
      :hide-dropdown="true"
      :focus-always="true"
      :input.sync="searchInput"
      label="Введите должность"
      size="sm"
      @add:chips="addPositions"
      @update:input="onUpdateInput"
    />
    <div class="list mt-4">
      <div
        v-for="position in filteredPositions"
        :key="position.id"
        class="list__item list__item_md flex items-center justify-between mb-3"
        @click="select(position)"
      >
        <div>{{ position.name }}</div>
        <div>{{ position.rate }}</div>
      </div>
      <template v-if="!filteredPositions.length">
        <div class="flex flex-center list__not-found">
          <img alt="not found" src="/assets/svg/not-found.svg" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CSearchInput from './SearchInput'

export default {
  name: 'PositionsSearchList',

  components: {
    CSearchInput,
  },

  props: {
    positions: {
      type: Array,
      default: () => [],
    },

    stagePositions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filter: '',
      searchInput: '',
      selectedPositions: this.stagePositions.slice(),
    }
  },

  computed: {
    filteredPositions() {
      const selectedPositionIds = this.selectedPositions.map(
        (selectedPosition) => selectedPosition.id
      )

      const positions = this.positions.filter(
        (position) => selectedPositionIds.indexOf(position.id) === -1
      )
      const regEx = new RegExp(this.filter, 'i')

      return positions.filter((p) => regEx.test(p.name))
    },
  },

  methods: {
    select(position) {
      this.selectedPositions.push(position)
      this.searchInput = ''
    },

    onUpdateInput(value) {
      this.filter = value
    },

    addPositions(positions) {
      this.$emit('add:positions', positions)
    },
  },
}
</script>
