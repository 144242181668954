export const dayWidth = 70
export const weekWidth = 110

export default [
  {label: 'Пн', value: '30.08', width: dayWidth},
  {label: 'Вт', value: '31.08', width: dayWidth},
  {label: 'Ср', value: '01.09', width: dayWidth},
  {label: 'Чт', value: '02.09', width: dayWidth},
  {label: 'Пт', value: '03.09', width: dayWidth},
  {label: 'Сб', value: '04.09', width: dayWidth},
  {label: 'Вс', value: '05.09', width: dayWidth},
  {label: 'Тек неделя', value: '30.08-03.09', width: weekWidth},
]
