<template>
  <div class="content mt-8">
    <div class="flex items-center">
      <BmButton class="mr-4" flat outlined round size="sm">
        <BmIconArrowLeft
          @click.native.prevent="$router.push({name: 'ProjectsPage'})"
        />
      </BmButton>

      <h1 class="title-h1">
        <BmSkeleton v-if="loading" height="25px" type="rect" width="180px" />

        {{ project.name }}
      </h1>
    </div>

    <ProjectHead
      v-if="!lodash.isEmpty(project)"
      :loading="loading"
      :project="project"
      class="my-4"
    />

    <div v-if="hasStages" class="flex no-wrap items-center mb-4">
      <div
        v-for="stage in project.stages"
        :key="stage.id"
        :class="
          stage.id === currentStageId ? 'stage_theme_dark' : 'stage_theme_light'
        "
        class="stage mr-2"
        @click="setCurrentStage(stage.id)"
      >
        <div class="stage__header">
          <div class="stage__title">{{ stage.name }}</div>
          <div v-if="stage.periodString" class="stage__period ml-auto">
            {{ stage.periodString }}
          </div>
        </div>
        <div class="stage__body">
          <div
            :class="
              stage.id === currentStageId
                ? 'budget_theme_light'
                : 'budget_theme_dark'
            "
            class="budget"
          >
            <div class="budget__cost">
              <template v-if="!stage.expenses">Нет данных по затратам</template>
              <template v-else>
                <div class="budget__left">
                  {{ numberWithSpaces(stage.expenses) }} ₽
                </div>
                <div class="budget__total">
                  / {{ numberWithSpaces(stage.budget) }} ₽
                </div>
              </template>
            </div>
            <div
              :class="
                stage.id === currentStageId
                  ? 'progress_theme_light'
                  : 'progress_theme_dark'
              "
              class="budget__progress progress"
            >
              <div :style="progressStyle(stage)" class="progress__line"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn__add-stage" @click="addStageDialog = true"></div>
    </div>

    <div v-if="currentStage" class="table__panel bg-white mb-4">
      <h2 class="title-h2">{{ currentStage.name }}</h2>
      <div class="ml-auto flex items-center">
        <BmButton
          class="mr-4"
          color="grey-dark"
          outlined
          rounded
          size="sm"
          @click.native="openAddEmployeeDialog"
        >
          <BmIconAdd bg-color="grey-dark" class="mr-2" />
          Сотрудник
        </BmButton>

        <BmButton outlined round size="sm">
          <BmIconMore bg-color="grey-dark" />
          <template #dropdown>
            <div class="drop-menu drop-menu_md">
              <div class="drop-menu__item drop-menu__item_separate">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <BmIconCalendar class="mr-2" />
                    Дата
                  </div>
                  <div>30.08–03.09 (Тек)</div>
                </div>
              </div>
              <div class="drop-menu__item" @click="openAddPositionDialog">
                <div class="flex items-center">
                  <BmIconAdd bg-color="grey-dark" class="mr-2" />
                  Добавить должность
                </div>
              </div>
              <div
                class="drop-menu__item drop-menu__item_separate"
                @click="openAddEmployeeDialog"
              >
                <div class="flex items-center">
                  <BmIconAdd bg-color="grey-dark" class="mr-2" />
                  Добавить сотрудника
                </div>
              </div>
              <div class="drop-menu__item drop-menu__item_separate" @click.stop>
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <BmIconRuble class="mr-2" />
                    План в рублях
                  </div>
                  <BmToggle v-model="inRublePlan" />
                </div>
              </div>
              <div class="drop-menu__item" @click="editStageDialog = true">
                <div class="flex items-center">
                  <BmIconPencil class="mr-2" />
                  Редактировать этап
                </div>
              </div>
              <div class="drop-menu__item" @click="onRemoveStage">
                <div class="flex items-center text-red">
                  <BmIconTrash class="mr-2" />
                  Удалить этап
                </div>
              </div>
            </div>
          </template>
        </BmButton>
      </div>
    </div>

    <StageTable
      v-if="currentStage"
      :hide-header="!hasStages || !hasCurrentStagePositions"
      :loading="loading"
      :plan-unit="planUnit"
      :stage="currentStage"
      @expand:position="expandPosition"
      @detach:position="detachPosition"
      @detach:user="detachUser"
    >
    </StageTable>

    <div v-if="!loading && !hasStages" class="area-add">
      <div class="area-add__label">
        Нет запланированных<br />
        этапов
      </div>
      <BmButton size="md" @click="addStageDialog = true">
        <BmIconAddBtn class="mr-2" />
        Добавить этап
      </BmButton>
    </div>

    <div
      v-if="!loading && currentStage && !hasCurrentStagePositions"
      class="area-add"
    >
      <div class="area-add__label">
        Нет запланированных<br />
        должностей
      </div>
      <BmButton class="mb-2" size="md" @click="addPositionDialog = true">
        <BmIconAddBtn class="mr-2" />
        Добавить должность
      </BmButton>
    </div>

    <BmModal :open.sync="editStageDialog" class="modal_sm">
      <template #header>
        <div class="title-h2">Редактировать этап</div>
      </template>

      <StageForm
        v-if="currentStage"
        :positions="positions"
        :stage-name="currentStage.name"
        :stage-period="{
          startDate: new Date(currentStage.startDate),
          endDate: new Date(currentStage.endDate),
        }"
        :stage-positions="currentStage.positions"
        class="mt-6"
        @submit:form="editStage"
      />
    </BmModal>

    <BmModal :open.sync="addStageDialog" class="modal_sm">
      <template #header>
        <div class="title-h2">Добавить новый этап</div>
      </template>

      <StageForm :positions="positions" class="mt-6" @submit:form="addStage" />
    </BmModal>

    <BmModal :open.sync="addPositionDialog" class="modal_lg">
      <template #header>
        <div class="title-h2">Добавить должность</div>
      </template>

      <PositionsSearchList
        :positions="positions"
        class="mt-6"
        @add:positions="addPositions"
      />
    </BmModal>

    <BmModal :open.sync="addUsersDialog" class="modal_lg">
      <template #header>
        <div class="title-h2">Добавить сотрудника</div>
      </template>

      <UsersSearchList
        :stage-positions="currentStagePositions"
        :users="users"
        class="mt-6"
        @add:users="addUsers"
      />
    </BmModal>

    <BmModal :open.sync="confirmRemoveDialog" class="modal_xs">
      <template #header>
        <div class="title-h2">
          {{ confirmDialog.title }}
        </div>
      </template>

      <div class="my-6">{{ confirmDialog.text }}</div>

      <div class="flex items-center justify-end no-wrap q-mt-md">
        <BmButton
          class="mr-2"
          flat
          label="Отменить"
          size="md"
          @click.native="modalReject"
        />
        <BmButton
          bg-color="red"
          label="Удалить"
          rounded
          size="md"
          @click.native="modalResolve"
        />
      </div>
    </BmModal>

    <BmNotify ref="notify" />
  </div>
</template>

<script>
import api from '@/api'
import {numberWithSpaces} from '@src/utils/helpers'
import StageForm from './_components/StageForm'
import StageTable from './_components/StageTable'
import PositionsSearchList from './_components/PositionsSearchList'
import UsersSearchList from './_components/UsersSearchList'
import ProjectHead from './_components/ProjectHead'

import {mapActions} from 'vuex'

export default {
  name: 'ProjectPage',

  components: {
    StageForm,
    StageTable,
    PositionsSearchList,
    UsersSearchList,
    ProjectHead,
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      positions: [],

      loading: false,

      project: {},

      currentStage: null,

      addStageDialog: false,
      editStageDialog: false,
      addPositionDialog: false,
      addUsersDialog: false,

      confirmRemoveDialog: false,
      confirmDialog: {},

      inRublePlan: false,

      currentDatePeriodId: null,
      datePeriodsList: [],
      users: [],

      modalResolve: null,
      modalReject: null,
    }
  },

  computed: {
    currentStageId() {
      return this.currentStage?.id
    },

    hasStages() {
      return this.project.stages?.length
    },

    currentStagePositions() {
      return this.currentStage?.positions || []
    },

    hasCurrentStagePositions() {
      return this.currentStagePositions.length > 0
    },

    planUnit() {
      return this.inRublePlan ? 'rub' : 'hour'
    },
  },

  methods: {
    ...mapActions({
      fetchProject: 'projects/fetchProject',
      fetchStage: 'stages/fetchStage',
      syncPositions: 'stages/syncPositions',
      syncUsers: 'stages/syncUsers',
      allPositions: 'positions/fetchAll',
      allUsers: 'users/fetchAll',
    }),

    async addStage(data) {
      const stage = await api.stages.create(this.project.id, {
        name: data.name,
        startDate: data.period.startDate?.toJSON(),
        endDate: data.period.endDate?.toJSON(),
      })

      if (stage?.id) {
        if (data.positions.length) {
          await this.syncPositions({
            stageId: stage.id,
            positionIds: data.positions.map((position) => position['id']),
          })
        }

        this.project.stages.push(stage)

        await this.setCurrentStage(stage.id)
      }

      this.addStageDialog = false
    },

    async editStage(data) {
      const stage = await api.stages.update(this.currentStageId, {
        name: data.name,
        startDate: data.period.startDate?.toJSON(),
        endDate: data.period.endDate?.toJSON(),
      })

      if (stage?.id) {
        await this.syncPositions({
          stageId: stage.id,
          positionIds: data.positions.map((position) => position['id']),
        })

        this.project.stages = this.project.stages.map((projectStage) => {
          if (stage.id === projectStage.id) {
            return stage
          }
          return projectStage
        })

        await this.setCurrentStage(stage.id)
      }

      this.editStageDialog = false
    },

    setDatePeriod(datePeriodId) {},

    expandPosition(positionId) {
      const index = this.currentStage.positions.findIndex(
        (p) => p.id === positionId
      )
      const position = {
        ...this.currentStage.positions[index],
        expand: !this.currentStage.positions[index].expand,
      }
      this.$set(this.currentStage.positions, index, position)
    },

    async addPositions(positions) {
      await this.syncPositions({
        stageId: this.currentStage.id,
        positionIds: this.currentStage.positions
          .concat(positions)
          .map((position) => position.id),
      })

      await this.setCurrentStage(this.currentStage.id)

      this.addPositionDialog = false

      this.$refs.notify.show('Должность добавлена', {
        type: 'success',
      })
    },

    async addUsers(users) {
      await this.syncUsers({
        stageId: this.currentStage.id,
        users: this.currentStage.users.concat(users),
      })

      await this.setCurrentStage(this.currentStage.id)

      this.addUsersDialog = false

      this.$refs.notify.show('Сотрудник добавлен', {
        type: 'success',
      })
    },

    openAddEmployeeDialog() {
      this.addUsersDialog = true
    },

    openAddPositionDialog() {
      this.addPositionDialog = true
    },

    confirmModal() {
      return new Promise((resolve, reject) => {
        this.confirmRemoveDialog = true
        this.modalResolve = () => {
          resolve()
        }
        this.modalReject = () => {
          reject()
        }
      })
    },

    async onRemoveStage() {
      try {
        this.confirmDialog = {
          title: 'Удалить этап?',
          text: 'Вы уверены что хотите удалить этот этап?',
        }
        await this.confirmModal()
        await api.stages.delete(this.currentStageId)
        this.project.stages = this.project.stages.filter(
          (stage) => stage.id !== this.currentStageId
        )
        this.currentStage = null
      } catch (e) {
        console.log(e)
      } finally {
        this.confirmRemoveDialog = false
      }
    },

    async detachPosition({stageId, positionId}) {
      try {
        this.confirmDialog = {
          title: 'Удалить должность?',
          text: 'Вы уверены что хотите удалить эту должность?',
        }

        await this.confirmModal()
        await api.stages.detachPosition(stageId, positionId)
        await this.setCurrentStage(stageId)
      } catch (e) {
        console.log(e)
      } finally {
        this.confirmRemoveDialog = false
      }
    },

    async detachUser({stageId, userId, positionId}) {
      try {
        this.confirmDialog = {
          title: 'Удалить сотрудника?',
          text: 'Вы уверены что хотите удалить этого сотрудника?',
        }
        await this.confirmModal()
        await api.stages.detachUser(stageId, userId)
        await this.setCurrentStage(stageId)
        this.expandPosition(positionId)
      } catch (e) {
      } finally {
        this.confirmRemoveDialog = false
      }
    },

    progressStyle(stage) {
      const percent = (+stage.expenses / +stage.budget) * 100
      return {
        width: (percent > 100 ? 100 : percent) + '%',
      }
    },

    async setCurrentStage(stageId) {
      this.currentStage = await this.fetchStage(stageId)
    },

    numberWithSpaces,
  },

  watch: {
    confirmRemoveDialog() {
      if (!this.confirmRemoveDialog) {
        this.confirmDialog = {}
      }
    },
  },

  async created() {
    this.project = await this.fetchProject(this.id)
    this.positions = await this.allPositions()
    this.users = await this.allUsers()

    await this.setCurrentStage(this.project.stages[0]?.id)

    document.title = 'Brave Manager - ' + this.project.name
  },
}
</script>
