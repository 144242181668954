<template>
  <div class="project-head">
    <div class="project-head__item">
      <div class="project-head__label">Клиент</div>
      <div class="project-head__value">
        <BmSkeleton v-if="loading" type="text" width="80px" height="15px" />
        <template v-else>
          <BmInput
            v-model="project.client.name"
            class="input_sm input_secret -ml-3"
            @completed:input="setClientName"
          />
        </template>
      </div>
    </div>
    <div class="project-head__item">
      <div class="project-head__label">Затраты</div>
      <div class="project-head__value">
        <BmSkeleton v-if="loading" type="text" width="80px" height="15px" />
        <template v-else>
          {{ project.expenses }}
        </template>
      </div>
    </div>
    <div class="project-head__item">
      <div class="project-head__label">Бюджет</div>
      <div class="project-head__value">
        <BmSkeleton v-if="loading" type="text" width="80px" height="15px" />
        <template v-else>
          <BmInput
            v-model="project.budget"
            class="input_sm input_secret -ml-3"
            @completed:input="setProjectBudget"
          />
        </template>
      </div>
    </div>
    <div class="project-head__item">
      <div class="project-head__label">Сроки</div>
      <div class="project-head__value">
        <BmSkeleton v-if="loading" type="text" width="80px" height="15px" />
        <template v-else>
          <BmDate
            :value="project"
            size="sm"
            secret
            clearable
            class="-ml-3"
            @input="setProjectPeriod"
          />
        </template>
      </div>
    </div>
    <div class="project-head__item">
      <div class="project-head__label">Менеджер</div>
      <div class="project-head__value">
        <BmSkeleton v-if="loading" type="circle" width="24px" height="24px" />
        <template v-else>
          <div class="avatar-group">
            <BmAvatar
              v-for="(manager, idx) in project.managers"
              :key="manager.id"
              :style="avatarStyles(idx)"
              :src="manager.avatar"
              size="md"
              bordered
              v-tooltip="manager.name"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'ProjectHead',
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    avatarStyles(idx) {
      return {
        transform: 'translateX(-' + idx * 40 + '%)',
      }
    },

    setClientName(value) {
      api.clients.update(this.project.client.id, {
        name: value,
      })
    },

    setProjectBudget(value) {
      api.projects.update(this.project.id, {
        budget: value,
      })
    },

    setProjectPeriod(value) {
      api.projects.update(this.project.id, value)
    },
  },
}
</script>
