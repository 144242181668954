<template>
  <div class="table">
    <div class="table__tbody">
      <div
        class="table__tr table__tr_body"
        v-for="n in currentRowsCount"
        :key="n"
      >
        <div class="table__td pr-8">
          <div class="table__td-content">
            <div style="width: 40px"></div>
            <div :style="cols.name.style">
              <div class="table__td-label">
                <BmSkeleton
                  animation="blink"
                  :type="columns.name.skeleton.type"
                  :width="getSize(columns.name.skeleton.width) + 'px'"
                />
              </div>
            </div>
            <div :style="cols.rate.style">
              <div class="table__td-label">
                <BmSkeleton
                  animation="blink"
                  :type="columns.rate.skeleton.type"
                  :width="getSize(columns.rate.skeleton.width) + 'px'"
                />
              </div>
            </div>
            <div :style="cols.plan.style">
              <div class="table__td-label">
                <BmSkeleton
                  animation="blink"
                  :type="columns.plan.skeleton.type"
                  :width="getSize(columns.plan.skeleton.width) + 'px'"
                />
              </div>
            </div>
            <div :style="cols.left.style">
              <div class="table__td-label">
                <BmSkeleton
                  animation="blink"
                  :type="columns.left.skeleton.type"
                  :width="getSize(columns.left.skeleton.width) + 'px'"
                />
              </div>
            </div>

            <div class="flex items-center ml-auto no-wrap">
              <div
                v-for="(period, idx) in columns.periods"
                :key="idx"
                :style="{width: dayWidth + 'px'}"
                class="flex items-center justify-end"
              >
                <BmSkeleton
                  animation="blink"
                  :type="period.skeleton.type"
                  :width="getSize(period.skeleton.width) + 'px'"
                />
              </div>
              <div
                class="flex items-center justify-end"
                :style="{width: weekWidth + 'px'}"
              >
                <BmSkeleton
                  animation="blink"
                  :type="columns.week.skeleton.type"
                  :width="getSize(columns.week.skeleton.width) + 'px'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {randomInteger} from '@src/utils/helpers'

import projectPageCols from '../_config/projectPageCols'
import {dayWidth, weekWidth} from '../_config/projectPageColumns'

export default {
  name: 'SkeletonStagesTable',
  props: {
    rowsCount: {
      type: Number,
    },
  },
  data() {
    return {
      currentRowsCount: this.rowsCount || 5,
      dayWidth,
      weekWidth,

      cols: projectPageCols,
      columns: {
        name: {skeleton: {type: 'rect', width: 80}},
        rate: {skeleton: {type: 'rect', width: 40}},
        plan: {skeleton: {type: 'rect', width: 40}},
        left: {skeleton: {type: 'rect', width: 40}},
        periods: [
          {name: 'period1', skeleton: {type: 'rect', width: 20}},
          {name: 'period2', skeleton: {type: 'rect', width: 20}},
          {name: 'period3', skeleton: {type: 'rect', width: 20}},
          {name: 'period4', skeleton: {type: 'rect', width: 20}},
          {name: 'period5', skeleton: {type: 'rect', width: 20}},
          {name: 'period6', skeleton: {type: 'rect', width: 20}},
          {name: 'period7', skeleton: {type: 'rect', width: 20}},
        ],
        week: {skeleton: {type: 'rect', width: 80}},
      },
    }
  },
  methods: {
    getSize(value) {
      if (Array.isArray(value)) {
        return randomInteger(...value)
      }
      return value
    },
  },
}
</script>
