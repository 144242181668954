<template>
  <label class="input-label" :class="labelClass">
    <input
      type="text"
      class="text-input"
      :class="inputClass"
      v-model="currentValue"
      @focus="onFocus"
      @blur="setCurrentValue"
      @keydown="onKeydown"
      ref="input"
    />
  </label>
</template>

<script>
const allowKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Enter',
  'Backspace',
  'Delete',
]

export default {
  name: 'TableTextInput',
  emits: {
    input: null,
  },
  props: {
    isSyncing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    max: {
      type: Number,
      default: 8,
    },
    unit: {
      type: String,
      default: 'ч',
    },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    labelClass() {
      return {
        'input-label_focused': this.isFocused,
        'input-label_error': this.isFocused && this.isMax,
      }
    },
    inputClass() {
      return {
        'text-input_syncing': this.isSyncing,
        'text-input_invalid': this.isMax,
        'text-input_error': this.isMax,
        'text-grey-5': parseInt(this.currentValue) === 0,
      }
    },
    isMax() {
      return parseInt(this.currentValue) > parseInt(this.max)
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true
      this.currentValue = parseInt(
        this.currentValue.toString().match(/\d+/g)?.join('')
      )
      this.$nextTick(() => {
        this.$refs.input.select()
      })
    },
    prepareValue() {
      let currentValue = this.currentValue.toString().replace(/[^0-9]/g, '')
      return currentValue ? currentValue : 0
    },
    onKeydown(event) {
      if (!allowKeys.includes(event.key)) {
        event.preventDefault()
        return false
      }

      if (event.key === 'Enter') {
        this.setCurrentValue()
        this.$refs.input.blur()
      }
    },
    setCurrentValue() {
      this.isFocused = false
      this.currentValue = this.prepareValue()
    },
  },
  mounted() {
    this.setCurrentValue()
  },
}
</script>
