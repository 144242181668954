<template>
  <div style="overflow: hidden; max-height: 670px">
    <CSearchInput
      :chips.sync="selectedUsers"
      :hide-dropdown="true"
      :focus-always="true"
      :input.sync="searchInput"
      label="Введите имя сотрудника"
      size="sm"
      @add:chips="addUsers"
      @update:input="onUpdateInput"
    />
    <div class="list mt-4">
      <template v-if="currentUserUnitUsers.length">
        <div class="list__subtitle">Твой юнит</div>
        <div
          v-for="user in currentUserUnitUsers"
          :key="user.id"
          class="list__item flex items-center justify-between"
          @click="select(user)"
        >
          <div class="flex items-center">
            <BmAvatar
              :label="initials(user.name)"
              :src="user.avatar"
              class="mr-2"
              size="lg"
            />
            <div>
              <div>{{ user.name }}</div>
              <div class="text-grey-dark">{{ user.unit }}</div>
            </div>
          </div>
          <div>
            <BmBadge v-if="user.position" :label="user.position.name" />
          </div>
        </div>
      </template>
      <template v-if="otherUsers.length">
        <div class="list__subtitle">Остальные</div>
        <div
          v-for="user in otherUsers"
          :key="user.id"
          class="list__item flex items-center justify-between"
          @click="select(user)"
        >
          <div class="flex items-center">
            <BmAvatar
              :label="initials(user.name)"
              :src="user.avatar"
              class="mr-2"
              size="lg"
            />
            <div>
              <div>{{ user.name }}</div>
              <div v-if="user.units.length" class="text-grey-dark">
                {{ user.units[0].name }}
              </div>
            </div>
          </div>
          <div>
            <BmBadge v-if="user.position" :label="user.position.name" />
          </div>
        </div>
      </template>
      <template v-if="!filteredUsers.length">
        <div class="flex flex-center list__not-found">
          <img alt="not found" src="/assets/svg/not-found.svg" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {initials} from '@src/utils/helpers'

import CSearchInput from './SearchInput'

export default {
  name: 'UsersSearchList',

  components: {
    CSearchInput,
  },

  props: {
    users: {
      type: Array,
      default: () => [],
    },

    stagePositions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedUsers: [],
      searchInput: '',
      filter: '',
    }
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
    }),

    filteredUsers() {
      const users = this.users
        .filter((user) => !this.selectedUsers.includes(user))
        .filter((user) =>
          this.stagePositions.find(
            (position) => position.id === user.position?.id
          )
        )

      const regEx = new RegExp(this.filter, 'i')

      return users.filter((user) => regEx.test(user.name))
    },

    currentUserUnitUsers() {
      return this.filteredUsers.filter((filteredUser) => {
        if (
          this.currentUser.units.length === 0 ||
          filteredUser.units.length === 0
        ) {
          return false
        }

        return (
          filteredUser.units.findIndex(
            (unit) => unit.id === this.currentUser.units[0].id
          ) !== -1
        )
      })
    },

    otherUsers() {
      return this.filteredUsers.filter((filteredUser) => {
        if (
          this.currentUser.units.length === 0 ||
          filteredUser.units.length === 0
        ) {
          return true
        }

        return (
          filteredUser.units.findIndex(
            (unit) => unit.id === this.currentUser.units[0].id
          ) === -1
        )
      })
    },
  },

  methods: {
    ...mapActions({
      fetchCurrentUser: 'users/fetchCurrent',
    }),

    select(user) {
      this.selectedUsers.push(user)
      this.searchInput = ''
    },

    onUpdateInput(value) {
      this.filter = value
    },

    addUsers(users) {
      this.$emit('add:users', users)
    },

    initials,
  },
}
</script>
