<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-2">
      <BmInput ref="inputName" v-model="name" label="Название" outlined />
    </div>

    <div class="mb-2">
      <BmDate v-model="period" />
    </div>

    <div class="mb-2">
      <CSearchInput
        :chips.sync="selectedPositions"
        :hide-dropdown="false"
        label="Должность"
        @update:input="onUpdateInput"
      >
        <template #dropdown>
          <div class="list list_dropdown">
            <div
              v-for="(position, idx) in filteredPositions"
              :key="idx"
              class="list__item flex items-center justify-between"
              @click="select(position)"
            >
              <div>{{ position.name }}</div>
              <div>{{ position.rate }}</div>
            </div>
          </div>
        </template>
      </CSearchInput>
    </div>

    <div class="flex items-center justify-end mt-4">
      <BmButton class="mr-2" flat label="Отменить" size="md" />
      <BmButton
        :label="stageName ? 'Изменить' : 'Добавить'"
        rounded
        size="lg"
        type="submit"
      />
    </div>
  </form>
</template>

<script>
import CSearchInput from './SearchInput'

export default {
  name: 'StageForm',

  components: {
    CSearchInput,
  },

  props: {
    stageName: {
      type: String,
      default: '',
    },

    stagePeriod: {
      type: Object,
      default: () => ({
        startDate: null,
        endDate: null,
      }),
    },

    stagePositions: {
      type: Array,
      default: () => [],
    },

    positions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      name: this.stageName,
      period: this.stagePeriod,
      selectedPositions: this.stagePositions,
      filter: '',
    }
  },

  computed: {
    filteredPositions() {
      const positions = this.positions.filter(
        (p) => !this.selectedPositions.includes(p)
      )
      const regEx = new RegExp(this.filter, 'i')

      return positions.filter((p) => regEx.test(p.name))
    },
  },

  methods: {
    onSubmit() {
      this.$emit('submit:form', {
        name: this.name,
        period: this.period,
        positions: this.selectedPositions,
      })
    },

    onUpdateInput(value) {
      this.filter = value
    },

    select(position) {
      this.selectedPositions.push(position)
    },
  },
}
</script>
