<template>
  <div v-click-outside="close" class="search" @click="open">
    <div :class="searchValueClasses" class="search__value">
      <div class="search__chips">
        <span v-for="(chip, index) in chips" :key="index" class="chip">
          <span class="chip__label">{{ chip.name }}</span>

          <BmIconTimes
            class="chip__close"
            @click.native.stop="removeChip(chip)"
          />
        </span>

        <input
          ref="input"
          v-model="input"
          class="search__input"
          type="text"
          @blur="focused = false"
          @focus="focused = true"
        />
      </div>

      <span v-if="label" :class="labelClasses" class="search__label">
        {{ label }}
      </span>

      <BmIconSearch
        :color="this.active ? 'black' : 'grey-light'"
        class="search__icon"
      />

      <BmButton
        v-if="hideDropdown"
        :class="searchAddBtnClasses"
        class="search__add-btn"
        label="Добавить"
        size="sm"
        @click.native="addChips"
      />
    </div>
    <transition name="fade-slide">
      <div v-if="!hideDropdown && active" class="search__dropdown" @click.stop>
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',

  props: {
    label: {
      type: String,
      default: '',
    },

    chips: {
      type: Array,
      default: () => [],
    },

    focusAlways: {
      type: Boolean,
      default: false,
    },

    hideDropdown: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'md',
    },

    input: {
      type: String,
    },
  },

  emits: ['add:chips'],

  data() {
    return {
      activating: false,
      active: false,
      focused: false,
    }
  },

  computed: {
    labelClasses() {
      return {
        search__label_stacked: this.input || this.focused || this.chips.length,
      }
    },

    searchValueClasses() {
      return [
        this.active && 'search__value_focused',
        this.hideDropdown && 'search__value_offset',
        `search__value_${this.size}`,
      ]
    },

    searchAddBtnClasses() {
      return {
        'search__add-btn_active': this.chips.length,
      }
    },
  },

  methods: {
    close() {
      if (this.activating) {
        return
      }
      this.active = false
    },

    open() {
      this.active = true
      this.$refs.input?.focus()
    },

    removeChip(currentChip) {
      this.$refs.input.focus()
      this.$emit(
        'update:chips',
        this.chips.filter((chip) => chip !== currentChip)
      )
    },

    addChips() {
      this.$emit('add:chips', this.chips)
    },
  },

  watch: {
    input() {
      this.$emit('update:input', this.input)
    },

    chips() {
      if (!this.chips.length) {
        this.$refs.input.blur()
      }
    },

    active() {
      if (this.active) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })

        this.activating = true
        setTimeout(() => (this.activating = false), 150)
      } else {
        this.filter = ''
      }
    },
  },

  created() {
    if (this.focusAlways) {
      setInterval(
        function () {
          this.$refs.input?.focus()
        }.bind(this),
        100
      )
    }
  },
}
</script>
